export enum TruckDocumentType {
  TAX_FILE,
  FITNESS_CERTIFICATE_FILE,
  ROUTE_PERMIT_FILE,
  CASE_FILE,
  OTHERS,
  OTHER_FILE,
  TRUCK_IMAGE,
  REG_PAPER_FILE,
  INSURANCE_FILE,
}

export enum DriverDocumentType {
  NID_FRONT = 1,
  NID_BACK = 2,
  DRIVING_LICENSE_FRONT = 3,
  DRIVING_LICENSE_BACK = 4,
  OTHER_FILE = 5,
}

export enum TransporterDocumentType {
  PROFILE_PICTURE = 1,
  NID_FRONT = 2,
  NID_BACK = 3,
  TRADE_LICENSE = 4,
  AGREEMENT = 5,
  OTHER_FILE = 6,
}
